<template>
  <div class="controle-patrimonial">
    <h1 class="titulo-card fs-4">Conexões</h1>

      <div class="row gx-md-3">
        <div class="col-12 col-lg-8">
          <div class="row">

            <!-- online -->
            <div class="col-6 col-md-4 col-lg-6 col-xl-4 px-md-3 mb-3">
              <div class="card card-raised border-start border-info border-4 text-white- bg-primary- cursor-pointer" @click="handleOnlineConnections()">
                <div class="card-body px-4">
                  <div class="overline text-muted mb-1">Ativas</div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="me-2">
                      <div class="display-6 me-3">{{counters.totalOnline}}</div>
                    </div>
                    <div class="d-flex align-items-center text-success">
                      <font-awesome-icon :icon="['fas', 'network-wired']" fixed-width class="text-muted"/>
                    </div>
                  </div>
                  <div class="card-text">
                    <div class="d-inline-flex align-items-center">
                      <div class="text-xs text-muted mb-1">Bloqueados: {{ counters.totalOnlineLate }} de {{ counters.totalLate }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- online -->

            <!-- offline -->
            <div class="col-6 col-md-4 col-lg-6 col-xl-4 px-md-3 mb-3">
              <div class="card card-raised border-start border-warning border-4 text-white- bg-primary- cursor-pointer" @click="handleSeeOffline()">
                <div class="card-body px-4">
                  <div class="overline text-muted mb-1">Offline</div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="me-2">
                      <div class="display-6 me-3">{{counters.totalOffline}}</div>
                    </div>
                    <div class="d-flex align-items-center text-success">
                      <font-awesome-icon :icon="['fas', 'thumbs-down']" fixed-width class="text-muted"/>
                    </div>
                  </div>
                  <div class="card-text">
                    <div class="d-inline-flex align-items-center">
                      <div class="text-xs text-muted mb-1">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- offline -->

            <!-- histórico -->
            <div class="col-6 col-md-4 col-lg-6 col-xl-4 px-md-3 mb-3">
              <div class="card card-raised border-start border-secondary border-4 text-white- bg-primary- cursor-pointer" @click="handleSeeHistory()">
                <div class="card-body px-4">
                  <div class="overline text-muted mb-1">Histórico</div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="me-2">
                      <div class="display-6 me-3">{{counters.totalHistory}}</div>
                    </div>
                    <div class="d-flex align-items-center text-success">
                      <font-awesome-icon :icon="['fas', 'history']" fixed-width class="text-muted"/>
                    </div>
                  </div>
                  <div class="card-text">
                    <div class="d-inline-flex align-items-center">
                      <div class="text-xs text-muted mb-1">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- histórico -->

          </div>
        </div>

        <div class="col-12 col-lg-4 mb-3">
          <div class="card">
            <img class="card-img-top" src="holder.js/100px180/" alt="">
            <div class="card-body border">
              <h4 class="card-title">BNG's</h4>
              <p class="card-text">
                <table class="table mb-0">
                  <tbody>
                    <tr v-for="bng in bngs" :key="bng.id">
                      <td scope="row">
                        <h5>
                          <font-awesome-icon :icon="['fas', 'server']" fixed-width class="ms-2 small"/>
                          {{bng.description}}
                        </h5>
                      </td>
                      <td width="100" class="text-end">
                        {{ totalBngConnections(bng.id) }} / {{bng.capacity}}
                        <br>
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animate" role="progressbar"
                                :class="bngProgressBarColor(bng.id, bng.capacity)"
                                :style="`width: ${percentBngConnections(bng.id, bng.capacity)}%;`" :aria-valuenow="totalBngConnections(bng.id)" aria-valuemin="0" :aria-valuemax="bng.capacity"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div class="me-2">
            <div class="display-6 me-3">ERROS DE CONEXÃO RECENTES</div>
          </div>
          <div class="card">
            <img class="card-img-top" src="holder.js/100px180/" alt="">
            <div class="card-body">
              <RadiusConnectionList class="d-none" />
              <h4 class="card-title">
                Resumo da últimas 3 horas
                <a href="/isp/connections/errors" class="btn btn-sm btn-primary ms-2">
                  Ver tudo
                  <font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width class="ms-1"/>
                </a>
              </h4>
              <p class="card-text">
                <RadiusRecentErrorList />
                <!-- <table class="table">
                  <tbody>
                    <tr v-for="connection in recentConnections" :key="connection.id" v-bind:class="classConnection(connection)">
                      <td scope="row" style="width: 135px;">{{godatetime().toMysql(connection.createdAt)}}</td>
                      <td>{{connection.username}}</td>
                      <td>{{connection.status}}</td>
                      <td>{{formatBytes(totalDownload(connection) + totalUpload(connection))}}</td>
                      <td align="center">{{connectionDuration(connection)}}</td>
                    </tr>
                  </tbody>
                </table> -->
              </p>
            </div>
          </div>
        </div>

      </div>

  </div>
</template>

<script>
// import Paginador from '@/components/Paginador.vue'
// import Tooltip from "@/components/Tooltip.vue";
import api from '../modules/api'
import dialogo from '../modules/dialogo'
import loading from '../modules/loading'
import clone from '../modules/clone'
import model from '../model'
import godatetime from '@/modules/godatetime'
// import pesquisaCliente from '@/modules/pesquisa-cliente'
import datetime from '@/modules/datetime'
import RadiusConnectionList from '../components/RadiusConnectionList.vue'
import RadiusRecentErrorList from '../components/RadiusRecentErrorList.vue'

export default {
  name: 'IspConnections',
  components: {
    RadiusConnectionList,
    RadiusRecentErrorList
},
  data() {
    return {
      counters: {
        totalOnline: 0,
        totalOnlineLate: 0,
        totalLate: 0,
        totalOffline: 0,
        totalHistory: 0,
        bngConnections: [],
      },
      bngs: [],
      recentConnections: [],


      movimentacoesRecentes: [],
      movimentacoesRecentesObjetos: [],
      movimentacoesRecentesClientes: [],
      modalObjeto: {
        objeto: undefined,
        form: {
          descricao: '',
          numeroDeSerie: '',
          dataAquisicao: '',
          valor: '0,00',
        }
      },

      modalMovimentacao: {
        objeto: undefined,
        pesquisa: '',
        listaObjetos: [],
        listaClientes: [],
        /** @type {model.paginador} */
        paginador: clone(model.paginador),
        guiaAtiva: '',
        form: {
          clienteSelecionado: undefined,
          pesquisaCliente: '',
          tipoMovimentacao: '',
          anotacao: '',
          colaboradorID: 0,
          clienteID: 0,
          assinaturaIspID: 0,
        }
      }
    }
  },

  methods: {
    godatetime() {
      return godatetime;
    },
    datetime() {
      return datetime;
    },

    totalDownload(session) {
      return (session.inputGigawords * (2 ** 32)) + session.inputOctets;
    },

    totalUpload(session) {
      return (session.outputGigawords * (2 ** 32)) + session.outputOctets;
    },

    // TODO: REFACTOR
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 b';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    refreshBngs() {
      loading(true);
      api.get('/isp/bngs?active=1&paginate.cancel&orderBy[description]=asc').then(res => {
        loading(false);

        this.bngs = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    refreshRecentConnections() {
      api.get('/isp/sessions?orderBy[id]=desc&limit=9').then(res => {
        this.recentConnections = res.data.items;
      });
    },

    refreshCounters() {
      api.get('/isp/sessions/counters').then(res => {
        this.counters.totalOnline = res.data.totalOnline;
        this.counters.totalOnlineLate = res.data.totalOnlineLate;
        this.counters.totalLate = res.data.totalLate;
        this.counters.totalOffline = res.data.totalOffline;
        this.counters.totalHistory = res.data.totalHistory;
        this.counters.bngConnections = res.data.bngConnections;
      });
    },

    handleOnlineConnections() {
      this.$router.push('/isp/connections/online');
    },

    handleSeeHistory() {
      this.$router.push('/isp/connections/history');
    },

    handleSeeOffline() {
      this.$router.push('/isp/connections/offline');
    },

    handleSeeErrors() {
      this.$router.push('/isp/connections/errors');
    },

    classConnection(connection) {
      if (connection.status === 'active') {
        return ['text-info'];
      }

      return ['text-muted-2'];
    },

    connectionDuration(connection) {
      let ref = new Date();

      switch (true) {
        case (connection.status == 'active'):
          ref = godatetime.toDateTime(connection.createdAt);
          break;

        case (!connection.sessionTime):
          return '-';

        default:
          ref = new Date(ref.getTime() + (connection.sessionTime * 1000));
      }

      return datetime.connectionDuration(ref);
    },

    totalBngConnections(bngId) {
      const bng = this.counters.bngConnections.find(x => x.id === bngId);
      if (!bng) {
        return 0;
      }

      return bng.total;
    },

    percentBngConnections(bngId, capacity) {
      const connections = this.totalBngConnections(bngId);
      let percent = Math.ceil((100 / capacity) * connections);

      return percent;
    },

    bngProgressBarColor(bngId, capacity) {
      const connections = this.totalBngConnections(bngId);
      let percent = Math.ceil((100 / capacity) * connections);

      switch (true) {
        case (percent > 75):
          return ['bg-danger'];

        case (percent > 50):
          return ['bg-warning'];

        default:
          return ['bg-primary'];
      }
    },

    //
  },

  created() {
    this.refreshBngs();
    this.refreshRecentConnections();
    this.refreshCounters();
  },

  destroyed() {
  },
}
</script>

<style>
.card-disabled {
  opacity: 0.5;
}

.card-raised {
    border: none;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    font-family: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card.cursor-pointer:hover {
  background-color: rgb(245, 245, 245);
}

.display-6 {
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    font-size: 1.25rem;
}

.overline {
    font-size: 0.75rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.1666666667em;
    text-decoration: none;
    text-transform: uppercase;
}

</style>