<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'
import godatetime from '@/modules/godatetime'
import datetime from '@/modules/datetime'

export default {
  name: "RadiusConnectionList",
  data() {
    return {
      sessionList: [],
    }
  },
  methods: {
    godatetime() {
      return godatetime;
    },
    datetime() {
      return datetime;
    },

    findConnections() {
      loading(true);
      api.get(`/isp/sessions`).then(res => {
        loading(false);
        this.sessionList = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    // TODO: REFACTOR
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 b';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    connectionDuration(connection) {
      let ref = new Date();

      switch (true) {
        case (connection.status == 'active'):
          ref = godatetime.toDateTime(connection.createdAt);
          break;

        case (!connection.sessionTime):
          return '-';

        default:
          ref = new Date(ref.getTime() + (connection.sessionTime * 1000));
      }

      return datetime.connectionDuration(ref);
    },

    totalDownload(session) {
      return (session.inputGigawords * (2 ** 32)) + session.inputOctets;
    },

    totalUpload(session) {
      return (session.outputGigawords * (2 ** 32)) + session.outputOctets;
    },
  },
  created() {
    this.findConnections();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Data</th>
            <th>Tipo de erro</th>
            <th>Mensagem</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="connection in sessionList" :key="connection.id">
            <td scope="row" style="width: 135px;">{{godatetime().toMysql(connection.createdAt)}}</td>
            <td>{{connection.username}}</td>
            <td>{{connection.status}}</td>
            <td>{{formatBytes(totalDownload(connection) + totalUpload(connection))}}</td>
            <td align="center">{{connectionDuration(connection)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
