<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'
import godatetime from '@/modules/godatetime'
import datetime from '@/modules/datetime'
import toMysql from '../modules/datetime/toMysql'

export default {
  name: "RadiusErrorList",
  data() {
    return {
      errorList: [],
      refreshInterval: undefined,
    }
  },
  methods: {
    godatetime() {
      return godatetime;
    },
    datetime() {
      return datetime;
    },

    findErrors() {
      let last3Hours = new Date()
      last3Hours.setHours(last3Hours.getHours() - 3);
      const dateLimitStr = toMysql(last3Hours)

      loading(true);
      api.get(`/isp/sessions/errors?orderBy[created_at]=desc&created_at[gte]=${dateLimitStr}`).then(res => {
        loading(false);
        this.errorList = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
  created() {
    this.findErrors();
    this.refreshInterval = setInterval(this.findErrors, 30000);
  },
  destroyed() {
    clearInterval(this.refreshInterval);
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Data</th>
            <th>MAC</th>
            <th>Login</th>
            <th>Tipo de erro</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="err in errorList" :key="err.id" class="text-danger">
            <td scope="row" style="width: 135px;">{{godatetime().toMysql(err.createdAt)}}</td>
            <td>{{err.mac}}</td>
            <td>{{err.username}}</td>
            <td>{{err.reason}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
